import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSheetContent, UitkSheet, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkTransparentToolbar } from "@egds/react-core/toolbar";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkCarousel, UitkCarouselProps } from "@egds/react-core/carousel";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { MediaGalleryDialogProps } from "../../typings";

export const MediaGalleryDialog = (props: MediaGalleryDialogProps) => {
  const { carouselProps, currentIndex, dialogId, images, startIndex } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const triggerMediaRef = React.useRef<HTMLElement | null>(null);

  const [isMediaGalleryDialogOpen, mediaDialogActions, MediaGalleryDialogComponent] = useDialog(dialogId);

  const closeMediaGalleryDialog = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    mediaDialogActions.closeDialog();
  };

  const galleryImageElements = images.map((image, index) => (
    <UitkFigure ratio={UitkFigureAspectRatioType.R16_9} key={`carousel-image-${index}`}>
      <UitkImage alt={image.alt} src={image.src} />
    </UitkFigure>
  ));

  const mediaCarouselProps: UitkCarouselProps = {
    ...carouselProps,
    children: galleryImageElements,
  };

  return (
    <UitkSheetTransition isVisible={isMediaGalleryDialogOpen}>
      <MediaGalleryDialogComponent>
        <UitkSheet isVisible={isMediaGalleryDialogOpen} type="full" triggerRef={triggerMediaRef} theme="dark">
          <UitkTransparentToolbar
            overlay
            navigationContent={{
              onClick: closeMediaGalleryDialog,
              navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
            }}
          />
          <UitkSheetContent padded={false}>
            <UitkLayoutFlex direction="column" blockSize="full_size">
              <UitkLayoutFlexItem>
                <UitkSpacing padding={{ blockstart: "twelve" }}>
                  <UitkCarousel
                    {...mediaCarouselProps}
                    fullSheetVisible={isMediaGalleryDialogOpen}
                    startIndex={startIndex}
                  />
                </UitkSpacing>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkSpacing padding={{ block: "four", inline: "three" }}>
                  <UitkLayoutFlex justifyContent="space-between" alignItems="end">
                    <UitkLayoutFlexItem>
                      <UitkText theme="inverse">{images[currentIndex].caption || ""}</UitkText>
                    </UitkLayoutFlexItem>
                    <UitkLayoutFlexItem>
                      <UitkText theme="inverse">{String(currentIndex + 1) + "/" + String(images.length)}</UitkText>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                </UitkSpacing>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkSheetContent>
        </UitkSheet>
      </MediaGalleryDialogComponent>
    </UitkSheetTransition>
  );
};

export default MediaGalleryDialog;
