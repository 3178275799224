import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import { GalleryCarouselProps } from "../typings";
import { getFullScreenImages } from "components/utility/GalleryImageUtil";
import { UitkCarousel, UitkCarouselProps } from "@egds/react-core/carousel";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage, UitkImageLink } from "@egds/react-core/images";
import { UitkOverlayButton } from "@egds/react-core/button";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { MediaGalleryDialog } from "./components/MediaGalleryDialog";
import { ThumbnailGalleryDialog } from "./components/ThumbnailGalleryDialog";

const GalleryCarousel = (props: GalleryCarouselProps) => {
  const { title, images, identifier, analyticsModule, locationId, imageStyle } = props;
  const track = useClickTracker();
  const { formatText } = useLocalization();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [startIndex, setStartIndex] = React.useState(0);
  const triggerThumbnailRef = React.useRef<HTMLElement | null>(null);

  const mediaGalleryQuery = identifier ? `mediaGallery${identifier}` : "mediaGallery";
  const thumbnailsGalleryQuery = identifier ? `thumbnailsGallery${identifier}` : "thumbnailsGallery";

  const [, mediaDialogActions] = useDialog(mediaGalleryQuery);
  const [, thumbnailsDialogActions] = useDialog(thumbnailsGalleryQuery);

  if (images.length === 0) {
    return null;
  }

  const galleryImages = getFullScreenImages(images);

  const onIndexChange = (index: number) => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery-nav.btn",
      action: Action.CLICK,
      linkName: "Navigating carousel gallery viewer",
    };

    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    setCurrentIndex(index);
  };

  const sendImageSeenTracking = (index: number) => {
    const mediaId = images[index]?.mediaId;

    if (mediaId) {
      const imageSeenTrackingInfo: FlexTrackingInfo = {
        moduleName: "GalleryMosaic",
        rfrr: "carousel.gallery-image.seen",
        action: Action.IMPRESSION,
        linkName: `mediaId ${mediaId} image viewed at location ${locationId}`,
      };
      sendImmediateClickstreamTrackEvent(imageSeenTrackingInfo, track);
    }
  };

  const openMediaGalleryDialog = (index: number) => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    setStartIndex(index);
    sendImageSeenTracking(index);
    mediaDialogActions.openDialog();
  };

  const openThumbnailsDialog = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarouselThumbnails",
      rfrr: "carousel.gallery.thumbnails.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    thumbnailsDialogActions.openDialog();
  };

  const galleryImageElements = galleryImages.map((image, index) => (
    <UitkFigure ratio={UitkFigureAspectRatioType.R16_9} key={`carousel-image-link-${index}`} style={imageStyle}>
      <UitkImage alt={image.alt} src={image.src} />
      <UitkImageLink>
        <button
          type="button"
          onClick={() => {
            openMediaGalleryDialog(index);
          }}
        >
          {image.caption}
        </button>
      </UitkImageLink>
    </UitkFigure>
  ));

  const carouselProps: UitkCarouselProps = {
    buttonText: {
      nextButton: formatText("carousel.image.item.next"),
      prevButton: formatText("carousel.image.item.prev"),
    },
    carouselHeaderLevel: "h2",
    carouselHeaderText: title,
    carouselName: analyticsModule,
    children: galleryImageElements,
    itemsVisible: {
      sm: 1,
      md: 1,
      lg: 1,
    },
    loop: true,
    onIndexChange: onIndexChange,
    pageBy: 1,
    pagingInset: true,
    peek: false,
  };

  return (
    <div className="GalleryCarousel" data-testid="galleryCarouselImage">
      <UitkCarousel {...carouselProps} />
      <UitkLayoutPosition type="relative">
        <UitkLayoutPosition type="absolute" position={{ bottom: "three", right: "three" }}>
          <UitkOverlayButton onClick={openThumbnailsDialog} data-testid="thumbnail-button" domRef={triggerThumbnailRef}>
            <UitkIcon
              name="photo_library"
              title={formatText("gallerycarousel.mediaGalleryButton", galleryImageElements.length)}
              id="photo_library-id"
            />
            <span aria-hidden="true">{String(galleryImageElements.length)}</span>
          </UitkOverlayButton>
        </UitkLayoutPosition>
      </UitkLayoutPosition>
      <MediaGalleryDialog
        carouselProps={carouselProps}
        currentIndex={currentIndex}
        dialogId={mediaGalleryQuery}
        images={galleryImages}
        startIndex={startIndex}
      />
      <ThumbnailGalleryDialog
        dialogId={thumbnailsGalleryQuery}
        imageElements={galleryImageElements}
        title={title}
        triggerThumbnailRef={triggerThumbnailRef}
      />
    </div>
  );
};

export default GalleryCarousel;
